import React, { memo, useEffect, useState } from 'react';
import classes from './OurApproach.module.scss';

function OurApproach(props){

    return (
        <div className={classes.our_approach}>
            <div className={classes.our_approach__content_container}>
                <div className={classes.our_approach__content_wrapper}> 
                    <h2 className={classes.our_approach__title}>Our approach</h2>
                    <div className={classes.our_approach__content_container}>
                        <p>We have researched and gathered the latest government data to inform the best possible approach to deliver the most representative national sample for UK research. The key area of focus is achieving representation of minority groups (alongside the traditional quotas of age, gender, region and social grade). Our sources and the related minority groups areas are:</p>
                        <p>
                            <ul>
                                <li>Ethnicity taken from the 2011 Census</li>
                                <li>Sexual Orientation taken from the 2017 Office of National Statistics NPS Data</li>
                                <li>Disability taken from 2011 Census, as well as elements of the forthcoming 2021 Census</li>
                            </ul>
                        </p>
                        <p>These sources are the most current and trusted sources that we can benchmark our approach to at this point in time. We will review our setup each year as new government data becomes available to ensure that it is as up-to-date as possible.</p>
                        <p>We have aimed to provide robust base sizes so that minimal weighting is required, and that the final data set delivers the best-case share of voice whilst minimising the margin of error.</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default memo( OurApproach )