import React, { memo, useEffect, useState } from 'react';
import classes from './BackgroundVideo.module.scss';
import { getFileName } from '../../helpers/FileCaching';

const videos = require.context('../../assets/videos', true, /.*\.(gif|png|jpe?g|svg|mp4)$/i);

function BackgroundVideo(props){

    return (
        <div className={classes.Background_video}>
            <div className={classes.Background_video__container}>

            
                    
                <video playsInline autoPlay muted loop>
                    <source src={getFileName(videos,"background_video.mp4")} type="video/mp4" />
                </video>

            </div>
            
        </div>
    )
}

export default memo( BackgroundVideo )