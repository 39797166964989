import React, { memo, useEffect, useState } from 'react';
import classes from './KeyGoals.module.scss';

function KeyGoals(props){

    return (
        <div className={classes.key_goals}>
            <div className={classes.key_goals__content_container}>
                <div className={classes.key_goals__content_wrapper}> 
                    <h2 className={classes.key_goals__title}>Key Goals</h2>
                    <div className={classes.key_goals__content_container}>
                        <p>To make ethnicity, sexual orientation and disability (in addition to age, gender, region and social grade) quotas a minimum requirement for all NatRep research samples.</p>
                        <p>To provide open and free resources to help enable all organisations involved in the procurement, delivery or use of NatRep UK Consumer Data to sign up and adopt the same approach detailed above. Joining us in guaranteeing that all UK Consumer Research samples are inclusive, socially responsible and a true representation of our wonderful and diverse nation.</p>
                        <p>Build and grow our list of signatories from all positions in the stakeholder chain to make this a minimum requirement.</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default memo( KeyGoals )