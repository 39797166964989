import React, { memo, useEffect, useState } from 'react';
import classes from './Hero.module.scss';
import { getFileName } from '../../helpers/FileCaching';

const logos = require.context('../../assets/images', true, /.*\.(gif|png|jpe?g|svg)$/i);

function Hero(props){

    return (
        <div className={classes.hero}>
            <div className={classes.hero__content_container}>
                <div className={classes.hero__content_wrapper}> 
                    <div className={classes.hero__logo_container}>
                        <img className={classes.hero__logo} alt={"Voices4All logo"} src={getFileName(logos,"V4A_logo.png")}/>
                    </div>
                    <h1 className={classes.hero__title}>‘A movement committed to improving diversity and inclusion standards within research services’</h1>
                </div>

            </div>
        </div>
    )
}

export default memo( Hero )