import React, { memo, useEffect, useState } from 'react';
import classes from './Introduction.module.scss';


function Introduction(props){

    return (
        <div className={classes.introduction}>
            <div className={classes.introduction__content_container}>
                <div className={classes.introduction__content_wrapper}> 
                    <h2 className={classes.introduction__title}>Introduction</h2>
                    <div className={classes.introduction__content_container}>
                        <p>Established in July 2020, Voices4all has the aim of creating a minimum standard for Nationally Representative (NatRep) UK research samples which not only statistically reflect age, gender, region and socio-demographic breakdowns but also proportionately reflect sexual orientation, ethnicity and disability.</p>
                        <p>You can read more about this in our open letter to the research and marketing industries <a target="_blank" href="Voices4AllOpenLetter.pdf">here</a>.</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default memo( Introduction )