import React, { memo, useEffect, useState } from 'react';
import classes from './Voices4allStandard.module.scss';

function Voices4allStandard(props){

    return (
        <div className={classes.Voices_4_all_standard}>
            <div className={classes.Voices_4_all_standard__content_container}>
                <div className={classes.Voices_4_all_standard__content_wrapper}> 
                    <h2 className={classes.Voices_4_all_standard__title}>How to adopt the Voices4all standard</h2>
                    <div className={classes.Voices_4_all_standard__content_container}>
                        <p>Read and review the Company Charter Registration document available <a target="_blank" href="Voices4AllCompanyCharterRegistration.pdf">here</a>. If agreeable send a signed copy to  <a href="mailto:info@voices4all.co.uk">info@voices4all.co.uk</a> and you will be contacted by the support team regarding next steps.</p>
                        <p>Download our guides to help you with <a target="_blank" href="Voices4AllDemographicQREGuide.pdf">demographic questions</a>, <a target="_blank" href="Voices4AllSampleWeightingRecommendations.pdf">quota set up, sampling and weighting</a></p>
                        <p>We provide full support at no cost. We are here to answer any questions and to help you overcome any roadblocks to adopting this approach.</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default memo( Voices4allStandard )