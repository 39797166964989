import React, { memo, useEffect, useState } from 'react';
import classes from './Logos.module.scss';
import { getFileName } from '../../helpers/FileCaching';

const logos = require.context('../../assets/images', true, /.*\.(gif|png|jpe?g|svg)$/i);

function Logos(props){

    return (
        <div className={classes.logos}>
            <div className={classes.logos__content_container}>
                <div className={classes.logos__content_wrapper}> 
                    <h2 className={classes.logos__title}>Voices4all Founders</h2>
                    <div className={classes.logos__logos_container}>
                        <div className={classes.logos__logo_wrapper}>
                            <div className={classes.logos__logo_container}>
                                <img alt={"Fleisman Hillard Fishburn logo"} src={getFileName(logos,"FHF_logo.jpg")} className={classes.logos__logo}/>
                            </div>
                            <div className={classes.logos__logo_container}>
                                <img alt={"Vitreous World logo"} src={getFileName(logos,"VitreousWorld_logo.png")} className={classes.logos__logo}/>
                            </div>
                            <div className={classes.logos__logo_container}>
                                <img alt={"Touchstone logo"} src={getFileName(logos,"touchstone_logo.PNG")} className={classes.logos__logo}/>
                            </div>
                            <div className={classes.logos__logo_container}>
                                <img alt={"Shopppecentric logo"} src={getFileName(logos,"logo_shoppercentric.jpg")} className={classes.logos__logo}/>
                            </div>
                            <div className={classes.logos__logo_container}>
                                <img alt={"TBS logo"} src={getFileName(logos,"tbs_logo.png")} className={classes.logos__logo}/>
                            </div>
                            <div className={classes.logos__logo_container}>
                                <img alt={"7 Stars logo"} src={getFileName(logos,"T7S_logo.png")} className={classes.logos__logo}/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default memo( Logos )