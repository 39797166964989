import React, { memo, useEffect, useState } from 'react';
import classes from './AccreditedMembers.module.scss';
import { getFileName } from '../../helpers/FileCaching';

const logos = require.context('../../assets/images', true, /.*\.(gif|png|jpe?g|svg)$/i);

function AccreditedMembers(props){

    return (
        <div className={classes.accredited_members}>
            <div className={classes.accredited_members__content_container}>
                <div className={classes.accredited_members__content_wrapper}> 
                    <h2 className={classes.accredited_members__title}>Voices4all Accredited Members</h2>
                    <div className={classes.accredited_members__accredited_members_container}>
                        <div className={classes.accredited_members__logo_wrapper}>
                            
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default memo( AccreditedMembers )