import React, { memo, useEffect, useState } from 'react';
import classes from './ContactForm.module.scss';

function ContactForm(props){

    const [formData, setFormData] = useState({name: '', company: '', email: ''})

    function saveFormData(){
        formData.name = document.getElementById('name_field').value;
        formData.email = document.getElementById('email_field').value;
        formData.company = document.getElementById('company_field').value;
        var formDataString = JSON.stringify({name:formData.name, email:formData.email, company:formData.company});
        //console.log(formDataString)
    
        var xhr = new XMLHttpRequest();
    
        xhr.open("POST", "https://f2r991ewfd.execute-api.eu-west-1.amazonaws.com/live/add-lead");
    
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");
    
        xhr.onreadystatechange = function() {//Call a function when the state changes.
            //console.log(xhr)
            if(xhr.readyState === 4 && xhr.status === 200) {
                var sentStatusCode = JSON.parse(xhr.responseText).statusCode;
                var sentStatusBody = JSON.parse(xhr.responseText).body;
                
                if(sentStatusCode === 200){
                    //console.log(xhr.responseText);
                    //console.log("data sent successfully")
                    document.getElementById("form_sending").style.display="none";
                    document.getElementById("form_sent").style.display="block";
                } else if(sentStatusCode === 400){
                    //console.log(xhr.responseText);
                    //console.log("issue sending data")
                    showSendError();
                    document.getElementById("form_sent_status").innerHTML = "Data Submitted is not valid " + sentStatusBody;
                }else{
                    //console.log("issue sending data")
                    showSendError();
                    document.getElementById("form_sent_status").innerHTML = "The was an error submitting your data. Please try again later";
                }
    
                
            } else if (xhr.status === 0) {
                //console.log("there was an error" )
                //console.log(xhr.readyState, " ", xhr.status )
                showSendError();
                document.getElementById("form_sent_status").innerHTML = "The was an error submitting your data. Please try again later";
            }
        }
        xhr.send(formDataString);
        
    }

    function showSendError(){
        document.getElementById("form_sending").style.display="none";
        document.getElementById("form_sent_status").style.display="block";
        document.getElementById("form_input").style.display="block";
    }

    function handleChange(event) {

        setFormData({
            name: formData.name,
            company: formData.company,
            email: formData.email
        })
        
    }
    
    function handleSubmit(event) {
        event.preventDefault();
        var data = {
            name: formData.name,
            company: formData.company,
            email: formData.email
        }
    }

    //data validation

    function dataValidation(e){
        e.preventDefault();
        //console.log("clicked")
        formData.name = document.getElementById('name_field').value;
        var nameValid;
        formData.email = document.getElementById('email_field').value;
        var emailValid;
        formData.company = document.getElementById('company_field').value;
        var companyValid;

        if (formData.company !== ""){
            //console.log("company valid")
            document.getElementById("form_company").innerHTML = "";
            companyValid = true;
        } else{
            document.getElementById("form_company").innerHTML = "*Company Required";
            //console.log("company invalid")
            companyValid = false;
        }
        if (formData.name !== ""){
            //console.log("name valid")
            document.getElementById("form_name").innerHTML = "";
            nameValid = true;
        }
        else{
            document.getElementById("form_name").innerHTML = "*Name Required";
            //console.log("name invalid")
            nameValid = false;
        }
        if( /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/.test(formData.email) ){
            //console.log("valid email")
            document.getElementById("form_email").innerHTML = "";
            emailValid = true;
        } else {
            document.getElementById("form_email").innerHTML = "*Enter a valid email";
            //console.log("invalid email")
            emailValid = false;
        }
        
        if(nameValid === true && emailValid === true && companyValid  === true){
            //console.log("submitted")
            saveFormData();
            document.getElementById("form_input").style.display="none";
            document.getElementById("form_sending").style.display="block";
        }else{
            //console.log("not submitted")
        }
    }

    // Clear out validation comments when text is entered
    useEffect(() => {

        if (formData.name !== ""){
            document.getElementById("form_name").innerHTML = "";
        }
    
        if (/^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/.test(formData.email)){
            document.getElementById("form_email").innerHTML = "";
        }
    
        if (formData.company !== ""){
            document.getElementById("form_company").innerHTML = "";
        }

    },[formData])

    return (
        <div className={classes.contact_form}>
            <div className={classes.contact_form__content_container}>
                <div className={classes.contact_form__content_wrapper}>   
                    <h2 className={classes.contact_form__title}>Register your Interest</h2>
                    <p className={classes.contact_form__info}>Our full website along with additional resources are currently under development. If you would like to register your interest to become a Voices4all signatory OR to receive further information, please complete the form below;</p>
                    <div className={classes.contact_form__form_container}>
                        <div className={classes.contact_form__form_input} id="form_input">
                            
                            <form className={classes.contact_form__form} onSubmit={handleSubmit}>
                                <ol className={classes.contact_form__form__list}>
                                    <li className={classes.contact_form__form__input_item}>
                                        <label id="form_company" className={classes.contact_form__form__form_validation}></label>
                                        <input type="text" onChange={handleChange} className={classes.contact_form__form__input} placeholder="Company" id="company_field"/>
                                    </li>
                                    <li className={classes.contact_form__form__input_item}>
                                        <label id="form_name" className={classes.contact_form__form__form_validation}></label>
                                        <input type="text" onChange={handleChange} className={classes.contact_form__form__input} placeholder="Contact Name" id="name_field"/>

                                    </li>
                                    <li className={classes.contact_form__form__input_item}>
                                        <label id="form_email" className={classes.contact_form__form__form_validation}></label>
                                        <input type="email" onChange={handleChange} className={classes.contact_form__form__input} placeholder="Contact Email" id="email_field"/>
                                    </li>
                                </ol>
                                <div className={classes.contact_form__form__submit_container}><p className={classes.contact_form__form__submit} onClick={dataValidation}>Register interest</p></div>
                                <p className={classes.contact_form__sent_status} id="form_sent_status"></p>
                            </form>
                        </div>
                        <div className={classes.contact_form__form_sending} id="form_sending">
                            <h2 className={classes.contact_form__title}>Your information is being sent</h2>
                            <div className={classes.contact_form__loading_animation}>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>   
                        </div>
                        <div className={classes.contact_form__form_sent} id="form_sent">
                            <h2 className={classes.contact_form__title}>Thank you for registering your interest</h2>
                        </div>
                    </div>
                    <p className={classes.contact_form__info}><span>PLEASE NOTE:</span> We will not be sending any additional updates to the interested parties until the 1st of September.<br/>Thank you so much for your interest.</p>
                </div>
            </div>
        </div>
    )
}

export default memo( ContactForm )