import React, { memo, useEffect, useState } from 'react';
import classes from './Footer.module.scss';

function Footer(props){

    return (
        <div className={classes.footer}>
            <div className={classes.footer__content_container}>
                <div className={classes.footer__content_wrapper}> 
                <h3 className={classes.footer__privacy}><a target="_blank" href="privacy">Privacy Policy</a></h3>
                    <h3 className={classes.footer__copy_right}>Copyright © Voices4All. All Rights Reserved.</h3>
                </div>

            </div>
        </div>
    )
}

export default memo( Footer )