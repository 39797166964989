import React, { memo, useEffect, useState } from 'react';
import classes from './PrivacyPolicy.module.scss';

function PrivacyPolicy(props){

    return (
        <div className={classes.privacy_policy}>
            <div className={classes.privacy_policy__content_container}>
                <div className={classes.privacy_policy__content_wrapper}> 
                    <h2 className={classes.privacy_policy__title}>Privacy Policy</h2>
                    <div className={classes.privacy_policy__content_container}>
                        <p>Voices4All regards the lawful and correct treatment of Personal Data and maintaining the confidence of those with whom it deals as a vital component of its business operations and is committed to act ethically and responsibly in respect of these Personal Data and to provide a high degree of confidentiality and security.
                        </p>
                        <p>To demonstrate these commitments, Voices4All adheres to the principles relating to the processing of Personal Data found in the GDPR. Voices4All respects the following principles, which are explained in more detail later, concerning Personal Data and that they are:
                            <ul className={classes.privacy_policy__bullet_list}>
                                <li>Processed fairly and lawfully.</li>
                                <li>Processed for limited purposes and in an appropriate way.</li>
                                <li>Adequate, relevant and not excessive for the purpose.</li>
                                <li>Not kept longer than necessary for the purpose.</li>
                                <li>Processed in line with Data Subjects’ rights.</li>
                                <li>Not transferred to people or organisations situated in other countries without adequate protection.</li>
                            </ul>
                        </p>
                        <h3>Information Collection</h3>
                        <p>Voices4All will only collect personally identifiable data of website users, such as your name or email address, when it is voluntarily submitted to us at this Website. We also collect non-personally identifiable information via various tracking utilities, as described below.</p>
                        <h3>What are the legal grounds for handling personal information?</h3>
                        <p>Data protection laws require that, where we process your personal data, we must satisfy at least one prescribed condition for processing. These are set out in data protection law and we rely on a number of different conditions for the activities we carry out.</p>
                        <p className={classes.privacy_policy__italic}><em>Necessary for performance of a contract or to comply with law</em></p>
                        <p>In most cases, the information described above will be provided to us by you because you want to take services from us or engage with us and our use of your information will be governed by contract terms. Giving this information to us is therefore your choice. If you choose not to give all or some of it to us, this may affect our ability to provide the services you want, to you. In particular, we may rely on this condition for processing in the following scenarios:
                            <ul>
                                <li>To enable you to access our website.</li>
                                <li>To let you know about significant changes to product terms or privacy policy.</li>
                                <li>To provide alerts.</li>
                                <li>To send you service communications.</li>
                            </ul>
                        </p>
                        <p className={classes.privacy_policy__italic}><em>Consent</em></p>
                        <p>Where we collect other information from you such as when we use cookies to collect information about the device you use to access our website, or sometimes third parties collect this on our behalf. You will be asked to consent to this before using our website. If you choose not to give your consent, or you later remove your consent, this may affect our ability to provide the services you want, to you. We may also rely on consent in the following scenarios:
                            <ul>
                                <li>Marketing – From time to time, we may like to let you know about products, services and offers from Voices4All, but only when relevant.</li>
                                <li>Market research – we may send you invitations to participate in market research (see below). If you do so, your feedback is given with your consent.</li>
                                <li>Administration of prize draws, competitions, membership offers, surveys and other promotional activities.</li>
                                <li>Necessary in our legitimate interests or those of a third party.</li>
                            </ul>
                        </p>
                        <p>In the United Kingdom, we can also use personal information where the benefits of doing it are not outweighed by the interests or fundamental rights or freedoms of individuals. The law calls this the “Legitimate Interests” condition for processing. Where we rely on it, the benefits being pursued by us are:
                            <ul>
                                <li>Internal training purposes – to enable us to train our staff to better provide services to our customers.</li>
                                <li>Reporting and analytical purposes – to provide management information and information to improve our services.</li>
                                <li>To maintain our records and other administrative purpose – to enable Voices4All to provide the most accurate data for our customers and clients.</li>
                                <li>Complaint and dispute resolution – we will need to use customer data when looking into queries and complaints.</li>
                                <li>To improve data accuracy and completeness – when you register for our services, you may supply us with additional information about yourself which we will use to improve our data accuracy and completeness.</li>
                                <li>Email tracking – in order to improve our communications to our customers.</li>
                                <li>Market research – to conduct market research studies on behalf of our clients.</li>
                            </ul>
                        </p>
                        <h3>Your Rights</h3>
                        <p>If you’ve given us consent to process your personal information, you have the right to withdraw that consent at any time by contacting us on <a href="mailto:DPO@voices4all.com">DPO@voices4all.com.</a></p>
                        <p>You can also ask for access to the personal information we hold about you and request that we correct any mistakes, restrict, stop processing or delete it. It’s worth noting that in some cases if you do ask us to correct, delete or stop processing it, we won’t always be required to do so. If that is the case, we will explain why.</p>
                        <p>In certain circumstances (e.g. where you provide your information to us (a) with consent to process it or (b) where the processing is necessary for the performance of our contract with you) you can require that we provide the information we hold about you either to you or a third party in a commonly used format. This only applies if we are processing it using automation only. If you would like more information about this, let us know by contacting us at <a href="mailto:DPO@voices4all.com">DPO@voices4all.com.</a></p>
                        <p>We will try to ensure that we deliver the best levels of customer service, but if you think we are falling short of that commitment, please let us know by contacting us at <a href="mailto:DPO@voices4all.com">DPO@voices4all.com.</a></p>
                        <p>If you’re still unhappy with any aspect of how we handle your personal information you also have the right to contact the Information Commissioner’s Office (ICO), the supervisory authority that regulates handling of personal information in the UK. You can contact them by:
                            <ul>
                                <li>Going to their website at <a target="_blank" href="https://ico.org.uk">https://ico.org.uk</a>.</li>
                                <li>Phone on 0303 123 1113.</li>
                                <li>Post to Information Commissioner’s Office, Wycliffe House, Water Lane, Wilmslow, SK9 5AF.</li>
                            </ul>
                        </p>
                        <h3>Information Use</h3>
                        <p>We will use this information only as described in this policy. We do not otherwise sell your personally identifiable information to third parties.</p>
                        <p>We use anonymised information within our market research operations the results of which we share with our clients.  From time to time, this might include some personally identifiable information, but only with explicit & informed consent from every individual.</p>
                        <p>For those users who provide a name, address or email address, we may communicate with you in response to your inquiries, to provide the services you request, to provide you information about Voices4All’s products and services and to add you to our newsletter and marketing lists.</p>
                        <p>If you are inquiring about employment with Voices4All, we may use and disclose your information in consideration of your application.</p>
                        <p>We may provide your personal information to third party companies that provide services to help us with our business activities such as offering customer service or marketing resources. These companies are authorised to use your personal information only as necessary to provide these services to us.</p>
                        <p>We may send you service-related announcements.</p>
                        <p>We may also disclose your personally identifiable information as required by law, such as to comply with a subpoena, or similar legal process or when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.</p>
                        <p>Personally identifiable information which you voluntarily disclose online in a publicly accessible blog, chat room, social media platform or other open forum, may be viewed and used by others without any restrictions. We are unable to control the use of your personally identifiable information by other users who see your information, and by using such services you assume the risk that the personally identifiable information provided by you may be viewed and used by third parties for any number of purposes.</p>
                        <h3>Information Sharing</h3>
                        <p>We share anonymised information with our market research clients as part of the research studies that we have conducted on their behalf and occasionally personally identifiable information where we have explicit & informed consent to do so.</p>
                        <p>Your personally identifiable information may be transferred if Voices4All is involved in a merger, acquisition, or sale of all or a portion of its assets.</p>
                        <p>We reserve the right to disclose your personally identifiable information in the rare but possible circumstance that the information is subject to disclosure pursuant to judicial or other government subpoenas, warrants, orders or for similar legal or regulatory requirements.</p>
                        <p>This Website is provided by Voices4All. The purpose of this privacy policy is to set out the principles governing our use of personal information that we collect from this Website. By using this Website, or by registering as a user of the services that we provide, you agree to this use. We ask you to read this privacy policy carefully.</p>
                        <p>For your convenience, this Website (www.vitreousworld.com) contains links to a number of other Websites. This privacy policy applies solely to the Website pages located at www.vitreousworld.com (hereafter the “Site”). It does not apply to any other party.</p>
                        <h3>Links to Other Sites</h3>
                        <p>If you visit a third party website that is linked from this Site, you should read that site’s privacy policy before providing any personally identifiable information. We are not responsible for the practices of such third party websites, and a link to such website is not an endorsement by Voices4All of such website’s privacy practices, products or services.</p>
                        <h3>Non-personal Information Collection and Use</h3>
                        <p>In common with many commercial organisations, we monitor the use of this Website by collecting aggregate information. We may automatically collect non-personal information about you such as the type of internet browser you use, the pages you visit or the Website which directed you to our site. This information is used to help us evaluate the viewing of content and services on this Website. We also collect non-personally identifiable information via various reporting and tracking utilities, as described below.</p>
                        <h3>Reporting & Tracking Utilities</h3>
                        <p className={classes.privacy_policy__italic}><em>Log Files:</em></p>
                        <p>As is true of most Websites, we gather certain information automatically and store it in log files. This information is related to the use of this Site and services and includes internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and clickstream data.</p>
                        <p>We use this information to analyse trends, to administer the Site, to track users’ movements around the Site and to gather demographic information about our user base as a whole.</p>
                        <p className={classes.privacy_policy__italic}><em>Cookies:</em></p>
                        <p>We may collect certain non-personally identifiable information in part through the use of a web technology called a “cookie.” A cookie is a small text file that is saved to your computer using your web browser, which allows websites to recognise you and store preferences and other information if you return to the website using the same computer and browser.</p>
                        <p>We use cookies for website analytics purposes to collect information about how visitors use our site. We use the information to compile reports and to help us improve the site.</p>
                        <p>We use both session ID cookies and persistent cookies. We use session cookies to make it easier for you to navigate our Site. A session ID cookie expires when you close your browser. A persistent cookie remains on your hard drive for an extended period of time. You control how your internet browser processes and saves cookies by following directions provided in your browser’s “help” file.</p>
                        <p>You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Site. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Site. If you delete your cookies, change browsers or use a different device, our cookie (or an opt-out cookie) may no longer work and you will have to re-input (or opt-out) again.</p>
                        <h3>Opt-Out</h3>
                        <p>If you no longer wish to receive email communications from Voices4All as described above or you would like us to suppress any details we hold about you, you may opt-out by following the directions that are included in each email communication. You may also contact us at <a href="mailto:DPO@voices4all.com">DPO@voices4all.com</a> for assistance.</p>
                        <h3>Security</h3>
                        <p>We have put in place reasonable administrative, technical, and physical safeguards to protect against unauthorised access to the information we collect. However, no security measures are 100% impenetrable and we cannot guarantee against all potential security breaches. Moreover, the transmission of information over the Internet is not inherently secure, and we are not responsible for the transmission of information over networks that we do not control. Your use of our Site demonstrates your assumption of these risks.</p>
                        <h3>Data Retention</h3>
                        <p>We will retain the information described above for as long as the information has value as part of the services we offer, or as long as is necessary to comply with our legal obligations, resolve disputes, and enforce our agreements, whichever is longer. If you wish to request that we no longer use your personally identifiable information to provide you services or information, please contact us at <a href="mailto:DPO@voices4all.com">DPO@voices4all.com.</a></p>
                        <h3>Children Under 13 Years Old</h3>
                        <p>This Website is not intended or designed to attract children under the age of 13. We do not knowingly collect personally identifiable data from or about any person under the age of 13. If you are under 13 years old and wish to ask a question or use this site in any way which requires you to submit your personal information, please get your parent or guardian to do so on your behalf.</p>
                        <h3>Correction/Updating Personal Information</h3>
                        <p>If you would like to check what personal information we hold about you, check that they are up to date, make changes or opt out please email <a href="mailto:DPO@voices4all.com">DPO@voices4all.com.</a> We respect your rights and comply with legal requirements as permitted by law.</p>
                        <h3>Changes in this Privacy Policy</h3>
                        <p>If we are going to use your previously collected personally identifiable information in a manner materially different from that stated in our privacy policy at the time of collection, you will be notified via email if possible. You will have a choice as to whether or not we use your information in this different manner and instructions for how to make that choice will be included in the notification. We will not use personally identifiable information in a manner materially different from that stated in our privacy policy at the time of collection without notifying you.</p>
                        <p>If we make a material change to this policy, we will post a prominent notice on this site for 30 calendar days prior to the implementation of the material change and describe how individuals may exercise any applicable choice. Following the implementation of the material change, we will record at the introduction of this policy when the policy was last revised.</p>
                        <h3>United States of America</h3>
                        <p>Some of our web and software servers may be maintained in the United States of America. By using the Site, you freely and specifically give us your consent to export your personally identifiable information to the USA and to store and use it in the USA as specified in this privacy policy.</p>
                        <p>Third party web service and data hosting providers may be utilised in some instances and data storage locations may be outside of the USA. These companies are contractually authorised to use your personal information only as necessary to provide these services to us and use reasonable administrative, technical, and physical safeguards to protect against unauthorised access.</p>
                        <p>You understand that our data is subject to lawful requests by the courts of law enforcement authorities in the United States.</p>
                        <p>If you have questions related to privacy, please contact us at <a href="mailto:DPO@voices4all.com">DPO@voices4all.com</a> or write to us at:</p>
                        <p>C/O Vitreous World - WeWork<br/>115 Mare Street <br/>London, E8 4RU <br/>Call: +44 (0) 203 764 2638</p>
                        <h3>Terms</h3>
                        <p>By accessing this Website, you agree to be bound by these terms and conditions (“terms”), please read them carefully. If you do not agree to be bound by these terms, you should not access or view this Website.
                            <ul className={classes.privacy_policy__bullet_list}>
                                <li>The information contained in this Website is intended for general information purposes only. Voices4All has made all reasonable efforts to ensure that the information on this Website is accurate at the time of inclusion, however there may be inaccuracies and occasional errors for which Voices4All apologises.</li>
                                <li>Voices4All makes no representations or warranties about the information provided through this Website, including any hypertext links to any Website or other items used either directly or indirectly from this Website. Voices4All accepts no liability for any inaccuracies or omissions in this Website and any decisions based on information contained in Voices4All’s Websites are the sole responsibility of the visitor.</li>
                                <li>The information and images contained within this Website are ©copyright Voices4All. We permit you to make copies of this Website as necessary incidental acts during your viewing of it; and you may take a print for your personal use of so much of the site as is reasonable for private purposes. You may not frame this site nor link to a page other than the home page without our express permission in writing.</li>
                                <li>You shall not use this Website for any illegal purposes and agree that you shall not send, use, copy, post or allow any posting which is defamatory or obscene within the meaning of the obscene publications act or which is abusive, indecent or in breach of the privacy of any person. You agree not to send any unsolicited promotional or advertising material, spam or similar materials or any volume messages that may interfere with the operation of this Website or with the enjoyment of this Website by other visitors.</li>
                                <li>Voices4All reserves the right at any time and without notice to enhance, modify, alter, suspend or permanently discontinue all or any part of this Website and to restrict or prohibit access to it.</li>
                                <li>You hereby agree to indemnify Voices4All against any costs, claims, losses and damages (including legal fees) incurred by or awarded against Voices4All as a result of your misuse of this Website or your breach of these terms.</li>
                                <li>If you are in breach of these terms or of Voices4All’s privacy policy, Voices4All may as its option, suspend or block your access to this Website and refuse to provide you with any further access to it.</li>
                                <li>This Website is provided to you free of charge, and neither Voices4All nor any of its subsidiary or affiliated companies accept any liability to you (except in the case of personal injury or death caused by its negligence or for fraud or as required by law) whether in contract, tort (including negligence) or otherwise, arising out of it in connection with this Website. Voices4All accepts no liability for any direct, special, indirect or consequential damages, or any other damages of whatsoever kind resulting from whatever cause through the use of any information obtained either directly or indirectly from this Website. Your sole remedy is to discontinue using this Website.</li>
                                <li>These terms may be amended by Voices4All from time to time.</li>
                            </ul>
                        </p>
                        <p>In the event that any or any part of the terms contained in these terms and conditions shall be determined by any competent authority to be invalid, unlawful or unenforceable to any extent, such term shall to that extent be severed from the remaining terms which shall continue to be valid and enforceable to the fullest extent permitted by law.</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default memo( PrivacyPolicy )