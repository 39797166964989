import React from 'react';
import logo from './logo.svg';
import classes from './App.scss';
import ContactForm from './components/ContactForm/ContactForm';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from './components/Hero/Hero';
import Introduction from './components/Introduction/Introduction';
import KeyGoals from './components/KeyGoals/KeyGoals';
import OurApproach from './components/OurApproach/OurApproach';
import Voices4allStandard from './components/Voices4allStandard/Voices4allStandard';
import Logos from './components/Logos/Logos';
import AccreditedMembers from './components/AccreditedMembers/AccreditedMembers';
import Footer from './components/Footer/Footer';
import Node from './components/Node/Node';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import BackgroundVideo from './components/BackgroundVideo/BackgroundVideo';

function App() {
  return (
    <Router>
    <div className="App">
      <BackgroundVideo></BackgroundVideo>

        <Switch>
          <Route exact path={`/`}>
            <div className="App__container">
              <div className="App__main_container">
               
                <Header></Header>
                <Introduction></Introduction>
                <KeyGoals></KeyGoals>
                <OurApproach></OurApproach>
                <Voices4allStandard></Voices4allStandard>
                {/* <ContactForm></ContactForm> */}
                <Logos></Logos>
                <AccreditedMembers></AccreditedMembers>
                <Footer></Footer>
              </div>
              <div className="App__node_container">
                <Node></Node>
              </div>
            </div>
          </Route>
          <Route path={`/privacy`}>
            <div className="App__container">
              <div className="App__main_container">
                <PrivacyPolicy></PrivacyPolicy>
              </div>
            </div>
          </Route>
        </Switch>
    </div>  
    </Router>
  );
}

export default App;
