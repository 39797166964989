import React, { memo, useEffect, useState } from 'react';
import classes from './Node.module.scss';
import { getFileName } from '../../helpers/FileCaching';

const logos = require.context('../../assets/images', true, /.*\.(gif|png|jpe?g|svg)$/i);

function Node(props){

    return (
        <div className={classes.node}>
            <div className={classes.node__content_container}>
                <div className={classes.node__content_wrapper}> 
                    <div className={classes.node__logo_container}>
                        <a href="https://www.nodelondon.com/" target="_blank">
                            <h4 className={classes.node__supported}>proudly supported by</h4>
                            <img className={classes.node__logo} alt={"Node logo"} src={getFileName(logos,"node_logo.svg")}/>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default memo( Node )